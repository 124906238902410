import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import HomeCategoriesCards from "../homeCategoriesCards/categoriesCards";
import { useTranslation } from "react-i18next";

function PinterestImagesCategory(props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={`container_custom mb-14 mt-3`}>
        <div className=" shadow-2xl bg-white   bg-opacity-50 lg:p-4  my-0 lg:my-8 rounded-3xl">
          <span>
            <span className="grid">
              <div className=" col-span-12 text-center  ">
                <h1>{t("New Categories")}</h1>
              </div>
              <Link
                to={{ pathname: `${props.Link}/all-categories` }}
                className="sm:block hidden absolute p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm "
              >
                {t("View All")}
              </Link>
            </span>
            <div className="sm:hidden block text-center">
              <Link
                to={{ pathname: `${props.Link}/all-categories` }}
                className=" relative p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm "
              >
                {t("View All")}
              </Link>
            </div>
          </span>
          <div className="row homeFirsBoxs">
            <HomeCategoriesCards
              hostLink={props.hostLink}
              Link={props.Link}
              language={props.language}
              limit={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PinterestImagesCategory;
