import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer(props) {
  const { t } = useTranslation();

  const [Logo, fetchLogo] = useState([]);
  const [Categories, fetchCategories] = useState([]);
  const [Projects, fetchProjects] = useState([]);
  const [Products, fetchProducts] = useState([]);
  const [About, fetchAbout] = useState([]);

  const getLogo = () => {
    fetch(`${props.hostLink}/nabatagri_backend/web/API/api/theme-settings`)
      .then((res) => res.json())
      .then((res) => {
        fetchLogo(res);
      });
  };

  const getCategories = () => {
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category-parents-ar?limit=5`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
        });
    } else {
      fetch(
        ` ${props.hostLink}/nabatagri_backend/web/API/api/category-parents?limit=5`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
        });
    }
  };
  const getProducts = () => {
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/products-ar?limit=5&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchProducts(res);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/products?limit=5&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchProducts(res);
        });
    }
  };

  const getProjects = () => {
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/projects-ar?limit=5&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchProjects(res);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/projects?limit=5&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchProjects(res);
        });
    }
  };

  const GetAbout = () => {
    if (props.language == "Ar") {
      fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about-ar`)
        .then((res) => res.json())
        .then((res) => {
          fetchAbout(res);
        });
    } else {
      fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about`)
        .then((res) => res.json())
        .then((res) => {
          fetchAbout(res);
        });
    }
  };
  useEffect(() => {
    GetAbout();
  }, [props.language]);

  useEffect(() => {
    getLogo();
  }, [props.language]);

  useEffect(() => {
    getProjects();
  }, [props.language]);

  useEffect(() => {
    getCategories();
  }, [props.language]);

  useEffect(() => {
    getProducts();
  }, [props.language]);

  return (
    <div className="py-10" style={{ backgroundColor: "rgb(55, 55, 55,0.8)" }}>
      <div className={`container_custom grid grid-cols-12`}>
        <div className="md:col-span-3 order-1 text-center md:order-1 sm:py-0 col-span-12 !text-white">
          <span
            style={{
              boxSizing: "border-box",
              display: "inline-block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: 0,
              margin: 0,
              padding: 0,
              position: "relative",
              maxWidth: "100%",
            }}
          >
            <img
              style={{
                display: "block",
                maxWidth: "100%",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
              }}
              alt=""
              aria-hidden="true"
              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27305%27%20height=%2797%27/%3e"
            />
            <Link to={`${props.Link}`}>
              {Logo.map((item, i) => {
                return (
                  <img
                    alt="Logo"
                    key={i}
                    src={item.logo}
                    decoding="async"
                    data-nimg="intrinsic"
                    className="invert w-32 "
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      boxSizing: "border-box",
                      padding: 0,
                      border: "none",
                      margin: "auto",
                      display: "block",
                      width: 0,
                      height: 0,
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "100%",
                      maxHeight: "100%",
                    }}
                    srcSet={item.logo}
                  />
                );
              })}
            </Link>
          </span>
        </div>
        <ul
          className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-3 col-span-12 md:col-span-9 ml-0 md:ml-20 order-1 md:order-5 ${
            props.language == "Ar" ? "text-right" : "text-left"
          }`}
        >
          <li className="mt-2 ">
            <span className=" text-white text-3xl">{t("Categories")}</span>
            <ul>
              {Categories.map((item, i) => {
                return (
                  <li key={i} className="  sm:my-4 ">
                    <Link
                      to={{
                        pathname: `${props.Link}/categories/${item.category_id}`,
                      }}
                      className="text-white"
                      href="/content/who-we-are"
                    >
                      - {item.category_name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
          <li className="mt-2 ">
            <span className=" text-white text-3xl">{t("Products")}</span>
            <ul>
              {Products.map((item, i) => {
                return (
                  <li key={i} className="  sm:my-4 ">
                    <Link
                      to={{
                        pathname: `${props.Link}/single-page/products/${item.product_id}`,
                      }}
                      className="text-white"
                    >
                      - {item.product_name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
          <li className="mt-2">
            <span className="text-white text-3xl">{t("Projects")}</span>
            <ul>
              {Projects.map((item, i) => {
                return (
                  <li key={i} className="  sm:my-4 ">
                    <Link
                      to={{
                        pathname: `${props.Link}/single-page/projects/${item.project_id}`,
                      }}
                      className="text-white"
                    >
                      - {item.project_name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>

        <div className=" col-span-12 order-10 ">
          <div className=" text-white grid  md:flex flex-row justify-around">
            <div className=" sm:mr-10">
              <h5 className="text-xl text-white mt-2  mb-3">
                <b>{t("Address")}</b>
              </h5>
              <span>{t("Nabat S.A.L.")}</span>
              <div>{About.address}</div>
              <span>{t("Nabat Nursery")}</span>
              <div>{About.nursery_address}</div>
            </div>
            <div className=" sm:mr-10">
              <h5 className="text-xl text-white mt-2  mb-3">
                <b>{t("Phone")}</b>
              </h5>
              <div
                className={`phoneNb ${
                  props.language == "Ar" ? "float-right" : ""
                }`}
              >
                {About.phone}
              </div>
              <div
                className={`phoneNb ${
                  props.language == "Ar" ? "float-right" : ""
                }`}
              >
                {About.phone_2}
              </div>
              <div
                className={`phoneNb ${
                  props.language == "Ar" ? "float-right" : ""
                }`}
              >
                {About.phone_3}
              </div>
            </div>
            <div className=" sm:mr-10">
              <h5 className="text-xl text-white mt-2  mb-3 ">
                <b>{t("Fax")}</b>
              </h5>
              <div
                className={`text-white phoneNb ${
                  props.language == "Ar" ? "float-right" : ""
                }`}
              >
                {About.fax}
              </div>
            </div>
            <div className=" sm:mr-10">
              <h5 className="text-xl text-white mt-2  mb-3">
                <b>{t("Mail")}</b>
              </h5>
              <a
                href={`mailto:${About.email}`}
                className="mb-2 text-white block"
              >
                {About.email}
              </a>
              <a
                href={`mailto:${About.email_2}`}
                className="mb-2 text-white block"
              >
                {About.email_2}
              </a>
            </div>
          </div>
          <div className="text-sm text-white mt-5 text-center" role="separator">
            &copy; {t("2022 Nabatagri, ")}
            {t("All rights reserved")}{" "}
          </div>
          <div className="text-sm text-white mt-1 text-center" role="separator">
            {t("Powered By")}
            <a
              className="!text-yellow-400"
              href="http://www.brainkets.com"
              target="_blank"
            >
              {" "}
              Brainkets
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
