import { Link } from "react-router-dom";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function Nav1(props) {
  // const [SelectedLanguage, setSelectedLanguage] = useState('En');
  const { t } = useTranslation();
  // $(function () {
  //   $(".selectpicker").selectpicker();
  // });

  const [Categories, fetchCategories] = useState([]);
  const getData = () => {
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category-parents-ar?limit=4`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category-parents?limit=4`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [props.language]);

  $(".navigation li").hover(function () {
    var isHovered = $(this).is(":hover");
    if (isHovered) {
      $(this).children("ul").stop().slideDown(100);
    } else {
      $(this).children("ul").stop().slideUp(100);
    }
  });

  return (
    <div className="text-center">
      <div className="dropdown inline-flex relative">
        <button className=" hover:text-green-600 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5">
          <Link
            className="sm:text-sm text-xs"
            to={{ pathname: `${props.Link}` }}
          >
            {t("Home")}
          </Link>
        </button>
        <button className=" hover:text-green-600 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5">
          <Link
            className="sm:text-sm text-xs"
            to={{ pathname: `${props.Link}/all-categories` }}
          >
            {t("Categories")}
          </Link>
        </button>
        <button className=" hover:text-green-600 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5">
          <Link
            className="sm:text-sm text-xs"
            to={{ pathname: `${props.Link}/all-products` }}
          >
            {t("Products")}
          </Link>
        </button>
        <button className=" hover:text-green-600 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5">
          <Link
            className="sm:text-sm text-xs"
            to={{ pathname: `${props.Link}/all-projects` }}
          >
            {t("Projects")}
          </Link>
        </button>
        <button className=" hover:text-green-600 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5">
          <Link
            className="sm:text-sm text-xs"
            to={{ pathname: `${props.Link}/about-us` }}
          >
            {t("About Us")}
          </Link>
        </button>
        <nav className="nav !hidden md:!flex">
          <ul className="navigation">
            {Categories.map((cat, i) => {
              if (cat.childs != "") {
                return (
                  <li key={i}>
                    <Link
                      to={{
                        pathname: `${props.Link}/categories/${cat.category_id}`,
                      }}
                      className=" font-semibold"
                      href="javascript:void(0);"
                    >
                      {cat.category_name}{" "}
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Link>
                    <ul className="bg-opacity-50 !h-auto">
                      {cat.childs.map((cat_child, i) => {
                        return (
                          <li key={i}>
                            <Link
                              className=" "
                              key={i}
                              to={{
                                pathname: `${props.Link}/categories/${cat_child.category_id}`,
                              }}
                              href="#"
                            >
                              {cat_child.category_name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}
export default Nav1;
