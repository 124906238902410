import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import RelatedProducts from "../../components/relatedProducts/relatedProducts";
import SlickSlider from "../../components/slickSlider/slickSlider";

function SinglePage(props) {
  const [NewItem, fetchNewItem] = useState([]);
  const [About, fetchAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  let { id } = useParams();
  let { productOrProject } = useParams();
  const GetItem = () => {
    if (props.language == "Ar") {
      if (productOrProject == "product") {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/product-single-page-ar?product_id= ` +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            fetchNewItem(res);
            setIsLoading(false);
          });
      } else {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/project-single-page-ar?project_id=` +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            fetchNewItem(res);
            setIsLoading(false);
          });
      }
    } else {
      if (productOrProject == "product") {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/product-single-page?product_id=` +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            fetchNewItem(res);
            setIsLoading(false);
          });
      } else {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/project-single-page?project_id=` +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            fetchNewItem(res);
            setIsLoading(false);
          });
      }
    }
  };
  const GetAbout = () => {
    fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about`)
      .then((res) => res.json())
      .then((res) => {
        fetchAbout(res);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    GetAbout();
  }, [props.language]);

  useEffect(() => {
    GetItem();
  }, [id, props.language]);

  return (
    <div className=" min-h-screen">
      {isLoading ? (
        <div
          className="spinner-border text-primary  relative  ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className=" py-10">
          {NewItem.map((item, i) => {
            return (
              <div className={`container_custom `} key={i}>
                <meta property="og:image" content={item.main_image} />
                <div className=" shadow-2xl bg-white   bg-opacity-50 lg:p-4 grid grid-cols-12 my-0 lg:my-8 rounded-3xl">
                  <div className=" col-span-12 lg:col-span-5 rounded-lg ">
                    <div className="w-full">
                      {productOrProject == "product" ? (
                        <SlickSlider
                          hostLink={props.hostLink}
                          Link={props.Link}
                          projectOrProduct="product"
                        />
                      ) : (
                        <SlickSlider
                          hostLink={props.hostLink}
                          Link={props.Link}
                          projectOrProduct="project"
                        />
                      )}
                    </div>

                    <a
                      href={`https://api.whatsapp.com//send?phone=${About.phone}&text=${props.hostLink}${props.Link}/single-page/product/${item.product_id}`}
                      data-action="share/whatsapp/share"
                      target="_blank"
                      className="share-icon"
                    >
                      <button
                        type="button"
                        className="btn btn-success !w-36 m-2  p-1 z-10  rounded-md text-gray-600 m-2 shadow-sm font-bold"
                      >
                        <i className="bi bi-whatsapp float-left"></i>
                        {t("For More Info")}
                      </button>
                    </a>
                  </div>
                  <div className=" col-span-12 lg:col-span-7 mx-2">
                    <div className="lg:pl-10 sm:px-2">
                      <span>
                        <span className=" font-semibold mt-3 block">
                          <h2
                            key={i}
                            className={`${
                              props.language == "Ar"
                                ? "float-right"
                                : "float-left"
                            }`}
                          >
                            {productOrProject == "product"
                              ? item.product_name
                              : item.project_name}
                          </h2>
                          {productOrProject == "product" && (
                            <Link
                              to={{
                                pathname: `${props.Link}/categories/${item.category_id}`,
                              }}
                              type="button"
                              className={`btn btn-success ${
                                props.language == "Ar"
                                  ? "float-left"
                                  : "float-right"
                              } rounded-md text-white shadow-sm font-bold`}
                            >
                              {item.category_name}
                            </Link>
                          )}
                        </span>

                        <div
                          className="inline-block"
                          dangerouslySetInnerHTML={{
                            __html:
                              productOrProject == "product"
                                ? item.product_description
                                : item.project_description,
                          }}
                        ></div>
                      </span>
                      <div>
                        <div
                          className="ant-divider ant-divider-horizontal"
                          role="separator"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <span>
                  {productOrProject == "product" ? (
                    <RelatedProducts
                      hostLink={props.hostLink}
                      Link={props.Link}
                      language={props.language}
                      product_id={item.product_id}
                      category_id={item.category_id}
                    />
                  ) : null}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
export default SinglePage;
