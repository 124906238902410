import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./carouselSwiper.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function CarouselSwiper(props) {
  const [NewProjects, fetchNewProjects] = useState([]);

  const limit = 2;

  const getNewProjects = () => {
    fetch(
      `${props.hostLink}/nabatagri_backend/web/API/api/projects?limit=${limit}&offset=0`
    )
      .then((res) => res.json())
      .then((res) => {
        fetchNewProjects(res);
      });
  };

  useEffect(() => {
    getNewProjects();
  }, []);

  return (
    <Swiper
      // install Swiper modules
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
      dir="ltr"
    >
      {NewProjects.map((project, i) => {
        return (
          <SwiperSlide key={i} style={{ height: "57vh" }}>
            <Link
              to={{
                pathname: `${props.Link}/single-page/project/${project.project_id}`,
              }}
            >
              <img
                className="w-full object-cover h-100"
                src={project.main_image}
                height="500"
                width="1680"
                alt="."
              />
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
export default CarouselSwiper;
