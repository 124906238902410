import ProjectsSwiperCardsAr from "./projectsSwiperCardsAr";
import ProjectsSwiperCardsEn from "./projectsSwiperCardsEn";
// import Swiper core and required modules

function ProjectsSwiperCards(props) {
  if (props.language == "Ar") {
    return (
      <ProjectsSwiperCardsAr
        hostLink={props.hostLink}
        Link={props.Link}
        limit={props.limit}
      />
    );
  } else {
    return (
      <ProjectsSwiperCardsEn
        hostLink={props.hostLink}
        Link={props.Link}
        limit={props.limit}
      />
    );
  }
}
export default ProjectsSwiperCards;
