import { useTranslation } from "react-i18next";
import ProjectsCards from "../../components/projectsCards/projectsCards";

function AllProjects(props) {
  const { t } = useTranslation();
  return (
    <div className="  w-full ">
      <div className={`container_custom  grid grid-cols-1`}>
        <div className=" col-span-12 text-center mb-2 ">
          <h1>{t("All Projects")}</h1>
        </div>
        <ProjectsCards
          hostLink={props.hostLink}
          Link={props.Link}
          language={props.language}
          productOrProject="projects"
        />
      </div>
    </div>
  );
}
export default AllProjects;
