import "./App.css";
import MainNav from "./components/navbar/mainNav";
import "antd/dist/antd.css";

function App() {
  //localhost:3000/
  return (
    <div className="bg">
      <MainNav />
    </div>
  );
}

export default App;
