import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// import { TextField } from "@mui/material";

function CategoriesCards(props) {
  const [Categories, fetchCategories] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(12);
  const [isLoading, setIsLoading] = useState(true);

  const getCategories = () => {
    if (props.language == "Ar") {
      fetch(
        `${
          props.hostLink
        }/nabatagri_backend/web/API/api/category-ar?offset=0&limit=${12}`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
          setIsLoading(false);
        });
    } else {
      fetch(
        `${
          props.hostLink
        }/nabatagri_backend/web/API/api/category?offset=0&limit=${12}`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getCategories();
  }, [props.language]);

  //load more start
  const getRemainingCategories = async () => {
    if (props.language == "Ar") {
      const res = await fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category-ar?offset=${offset}&limit=12`
      );
      const data = res.json();
      return data;
    } else {
      const res = await fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category?offset=${offset}&limit=12`
      );
      const data = res.json();
      return data;
    }
  };

  const fetchData = async () => {
    const infiniteScroll = await getRemainingCategories();
    fetchCategories([...Categories, ...infiniteScroll]);
    if (infiniteScroll.length === 0 || infiniteScroll.length < 12) {
      setHasMore(false);
    }
    setOffset(offset + 12);
  };
  //load more end

  return (
    <div className="min-h-screen">
      {isLoading ? (
        <div
          className="spinner-border text-primary  relative  ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={Categories.length} //This is important field to render the next data
          next={fetchData}
          hasMore={hasMore}
          scrollThreshold={0.5}
          loader={
            Categories.length >= 12 ? (
              <div
                className="spinner-border text-primary  relative  ml-auto mr-auto !block"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : null
          }
          className="overflow-hidden"
        >
          <div className="flow-root ">
            {Categories.length > 0 ? (
              Categories.map((categories, i) => {
                return (
                  <div
                    key={i}
                    className={`h-80  overflow-hidden p-4 ${
                      props.language == "Ar" ? "float-right" : "float-left"
                    } rounded-lg group  w-6/12 md:w-4/12`}
                  >
                    <Link
                      to={{
                        pathname: `${props.Link}/categories/${categories.category_id}`,
                      }}
                    >
                      <div className="w-100 position-relative h-100 overflow-hidden rounded-lg">
                        <div className=" absolute p-1 z-10 bg-white rounded-md text-gray-600 m-2 shadow-sm font-bold">
                          {categories.category_name}
                        </div>
                        <img
                          src={categories.category_image}
                          className="object-cover w-full h-full group-hover:scale-105 transition-all rounded-lg"
                        />
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <h1>No Categories Found</h1>
            )}{" "}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}
export default CategoriesCards;
