// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Swiper core and required modules

function ProjectsSwiperCardsAr(props) {
  const [NewProjects, fetchNewProjects] = useState([]);
  const { limit } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const getNewProjects = () => {
    if (!props.limit) {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/projects-ar?limit=${limit}&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchNewProjects(res);
          setIsLoading(false);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/projects-ar?limit=${props.limit}&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchNewProjects(res);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getNewProjects();
  }, [props.language]);
  const { t } = useTranslation();
  return (
    <div className={``}>
      <div className={`mx-auto w-11/12 py-5 `}>
        <div className=" shadow-2xl bg-white   bg-opacity-50 lg:p-4  my-0 lg:my-8 rounded-3xl">
          <span>
            <span className="grid">
              <div className=" col-span-12 text-center  ">
                <h1>{t("New Projects")}</h1>
                <h2 className="text-lg ">{t("Our Projects")}</h2>
              </div>
              <Link
                to={{ pathname: `${props.Link}/all-projects` }}
                className="sm:block hidden absolute p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm "
              >
                {t("View All")}
              </Link>
            </span>
            <div className="sm:hidden block text-center mb-2">
              <Link
                to={{ pathname: `${props.Link}/all-projects` }}
                className=" relative p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm "
              >
                {t("View All")}
              </Link>
            </div>
          </span>
          {isLoading ? (
            <div
              className="spinner-border text-primary  relative  ml-auto mr-auto !block"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <Swiper
              // install Swiper modules
              spaceBetween={0}
              breakpoints={{
                // when window width is >= 480px
                480: {
                  slidesPerView: 1,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1250: {
                  slidesPerView: 4,
                },
              }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log("slide change")}
            >
              {NewProjects.map((project, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Link
                      to={{
                        pathname: `${props.Link}/single-page/project/${project.project_id}`,
                      }}
                    >
                      <div
                        key={i}
                        className="swiper-slide swiper-slide-next mb-15"
                      >
                        <div
                          className={`mx-3 mb-9 bg-white   group overflow-hidden shadow-sm hover:shadow-xl float-left col-span-4 rounded-lg`}
                        >
                          <div className=" relative float-left h-full cursor-pointer ">
                            <div
                              className="w-full float-left"
                              style={{ height: "128px" }}
                            >
                              <div className="w-5/12 float-left relative ">
                                <img
                                  style={{ height: "128px" }}
                                  className="w-full bg-cover bg-center rounded-l-lg group-hover:scale-110 transition-all  object-cover"
                                  src={project.main_image}
                                  width="143"
                                  height="143"
                                  alt="Children jacket"
                                />
                              </div>
                              <div className="  float-left w-7/12">
                                <div className="  text-md relative ">
                                  <h3 className="w-full text-center float-left h-10 font-semibold overflow-hidden mt-2">
                                    {project.project_name}
                                  </h3>
                                  <p
                                    className={`font-semibold text-xs text-right mr-2`}
                                  >
                                    {project.project_brief_description &&
                                      project.project_brief_description}
                                    ...
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProjectsSwiperCardsAr;
