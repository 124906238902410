import { useEffect, useState } from "react";
import classes from "./topNav.module.css";
import { useTranslation } from "react-i18next";

function TopNav(props) {
  const [About, fetchAbout] = useState([]);
  const { t } = useTranslation();
  const GetAbout = () => {
    fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about`)
      .then((res) => res.json())
      .then((res) => {
        fetchAbout(res);
      });
  };

  useEffect(() => {
    GetAbout();
  }, []);
  return (
    <div className="menu">
      <ul className={`${classes.ul}`}>
        <span
          className={`phoneNb ${
            props.language == "Ar" ? classes.span2_rtl : classes.span2_ltr
          } mt-1`}
        >
          <i className="bi bi-telephone-fill sm:mx-2"></i>
          <a href={`tel:${About.phone}`}>{About.phone}/</a>
          <a href={`tel:${About.phone_2}`}>{About.phone_2}</a>
        </span>
        <li
          className={`${
            props.language == "Ar" ? classes.li_rtl : classes.li_ltr
          }`}
        >
          <span>{t("Welcome To Nabatagri Natural World")}</span>
        </li>
      </ul>
    </div>
  );
}
export default TopNav;
