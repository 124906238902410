import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function HomeCategoriesCards(props) {
  const [Categories, fetchCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCategories = () => {
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category-ar?offset=0&limit=${props.limit}`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
          setIsLoading(false);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/category?offset=0&limit=${props.limit}`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchCategories(res);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getCategories();
  }, [props.language]);

  return (
    <>
      {isLoading ? (
        <div
          className="spinner-border text-primary  relative  ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div>
          {Categories.map((categories, i) => {
            return (
              <div
                key={i}
                className="h-80  overflow-hidden p-4  inline-block rounded-lg group  md:w-6/12 sm:w-6/12  w-full lg:w-4/12"
              >
                <Link
                  to={{
                    pathname: `${props.Link}/categories/${categories.category_id}`,
                  }}
                >
                  <div className="w-100 position-relative h-100 overflow-hidden rounded-lg">
                    <div className=" absolute p-1 z-10 bg-white rounded-md text-gray-600 m-2 shadow-sm font-bold">
                      {categories.category_name}
                    </div>
                    <img
                      src={categories.category_image}
                      className="object-cover w-full h-full group-hover:scale-105 transition-all rounded-lg"
                    />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
export default HomeCategoriesCards;
