import IconsSwiperEn from "./iconsSwiperEn";
import IconsSwiperAr from "./iconsSwiperAr";
// import Swiper core and required modules

function IconsSwiper(props) {
  if (props.language == "Ar") {
    return <IconsSwiperAr hostLink={props.hostLink} Link={props.Link} />;
  } else {
    return <IconsSwiperEn hostLink={props.hostLink} Link={props.Link} />;
  }
}
export default IconsSwiper;
