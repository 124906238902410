import { Dropdown } from "bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Suggestion from "../suggestions/suggestions";
import since from "../../nabat_since_1983.PNG";
import $ from "jquery";

function Nav2(props) {
  const [Logo, fetchLogo] = useState([]);
  const [Suggestions, setSuggestions] = useState([]);
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(true);
  const [inputProducts, setInputProducts] = useState("");
  const getLogo = () => {
    fetch(`${props.hostLink}/nabatagri_backend/web/API/api/theme-settings`)
      .then((res) => res.json())
      .then((res) => {
        fetchLogo(res);
      });
  };
  function changedLanguage() {
    var selectedLanguage = $("#selectionLanguage")
      .find("option:selected")
      .val();
    props.setLanguage(selectedLanguage);
  }
  useEffect(() => {
    getLogo();
  }, []);

  //Products Search start

  let inputHandlerProducts = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputProducts(lowerCase);
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/search-suggestions-products-ar?key=${lowerCase}`
      )
        .then((res) => res.json())
        .then((res) => {
          setSuggestions(res);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/search-suggestions-products?key=${lowerCase}`
      )
        .then((res) => res.json())
        .then((res) => {
          setSuggestions(res);
        });
    }
    setShow(true);
    setOpen(true);
    props.setinputProduct(lowerCase);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      document.getElementById("search_btn").click();
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  Dropdown.handleClickOutside = () => setIsOpen(false);

  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: "rgb(137, 165, 142,0.2)" }}>
      <div className="w-full sm:flex justify-between pb-3 container_custom ">
        <div className=" sm:w-3/12 mr-3 md:w-2/12 md:mr-0  mt-3">
          <Link to={`${props.Link}`}>
            {Logo.map((item, i) => {
              return (
                <img
                  key={i}
                  src={item.logo}
                  width="169"
                  height="44"
                  className=" w-full sm:w-10/12 sm:mt-0  "
                  alt="Logo"
                />
              );
            })}
          </Link>
        </div>
        <div className="contents">
          <div className="float-left md:flex-auto mt-4 sm:mt-0 w-6/12 mr-12 md:w-auto md:mx-28  px-0 sm:px-12">
            <span className=" ant-input-search ant-input-search-middle ant-input-search-with-button sm:px-20 ">
              <span className="ant-input-wrapper ant-input-group d-flex">
                <input
                  onChange={inputHandlerProducts}
                  onKeyDown={handleKeypress}
                  type="text"
                  placeholder={t("Search for products...")}
                  className="ant-input form-control"
                  defaultValue={""}
                />

                <Link
                  id="search_btn"
                  to={{ pathname: `${props.Link}/search/${inputProducts}` }}
                  className="btn ant-input-group-addon w-auto"
                >
                  <button
                    disabled={!inputProducts}
                    type="button"
                    className={`ant-btn ant-btn-primary ant-input-search-button  !h-full`}
                  >
                    <span
                      role="img"
                      aria-label="search"
                      className={`anticon anticon-search`}
                    >
                      <svg
                        className={`search_color`}
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="search"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                      </svg>
                    </span>
                  </button>
                </Link>
              </span>
              <div>
                {
                  <Suggestion
                    hostLink={props.hostLink}
                    Link={props.Link}
                    Suggestions={Suggestions}
                    inputHandlerProducts={inputHandlerProducts}
                    setSuggestions={setSuggestions}
                    show={show}
                    setShow={setShow}
                    isOpen={isOpen}
                    open={open}
                    setOpen={setOpen}
                  />
                }
              </div>
            </span>
          </div>
          <div
            className={`flex justify-center mt-2  ${
              props.language == "Ar"
                ? "float-right  sm:float-left"
                : "float-left  sm:float-right"
            }`}
          >
            <div className="sm:!pt-5 mt-3 flex">
              <i className="bi bi-globe text-white text-xl "></i>
              {props.language == "Ar" ? (
                <select
                  onChange={changedLanguage}
                  id="selectionLanguage"
                  className="cursor-pointer h-max mt-4 !bg-none  !border-transparent !text-white
    appearance-none
    block
    w-full
    px-1
    sm:text-xl
    font-normal
    text-gray-700
    !bg-transparent bg-clip-padding bg-no-repeat
    relative !bottom-6
    rounded
    transition
    ease-in-out
    !m-0 !p-0
    focus:text-gray-700 focus:bg-transparent focus:!shadow-transparent  focus:outline-none"
                  aria-label=".form-select-lg example"
                >
                  <option className="text-slate-400" value="En">
                    English
                  </option>
                  <option className="text-slate-400" selected value="Ar">
                    عربية
                  </option>
                </select>
              ) : (
                <select
                  onChange={changedLanguage}
                  id="selectionLanguage"
                  className="cursor-pointer h-max mt-4 !bg-none  !border-transparent !text-white
    appearance-none
    block
    w-full
    px-1
    sm:text-xl
    font-normal
    text-gray-700
    !bg-transparent bg-clip-padding bg-no-repeat
    relative !bottom-6
    rounded
    transition
    ease-in-out
    !m-0 !p-0
    focus:text-gray-700 focus:bg-transparent focus:!shadow-transparent  focus:outline-none"
                  aria-label=".form-select-lg example"
                >
                  <option className="text-slate-400" selected value="En">
                    English
                  </option>
                  <option className="text-slate-400" value="Ar">
                    عربية
                  </option>
                </select>
              )}
              <i className={`bi bi-caret-down relative  text-white`}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Nav2;
