import { useTranslation } from "react-i18next";
import ProductsCards from "../../components/productsCards/productsCards";

function AllProducts(props) {
  const { t } = useTranslation();
  return (
    <div className="  w-full ">
      <div className={`container_custom  grid grid-cols-1`}>
        <div className=" col-span-12 text-center mb-2 ">
          <h1>{t("All Products")}</h1>
        </div>
        <ProductsCards
          hostLink={props.hostLink}
          Link={props.Link}
          language={props.language}
          productOrProject="products"
          allProducts="allProducts"
        />
      </div>
    </div>
  );
}
export default AllProducts;
