// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import Swiper core and required modules

function IconsSwiperEn(props) {
  const [Categories, fetchCategories] = useState([]);
  const getCategories = () => {
    fetch(
      `${props.hostLink}/nabatagri_backend/web/API/api/category-parents?limit=10`
    )
      .then((res) => res.json())
      .then((res) => {
        fetchCategories(res);
      });
  };
  useEffect(() => {
    getCategories();
  }, [props.language]);
  return (
    <div className="relative bottom-24">
      <Swiper
        className={`container_custom `}
        // install Swiper modules
        spaceBetween={10}
        breakpoints={{
          360: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1250: {
            slidesPerView: 5,
          },
        }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        {Categories.map((categories, i) => {
          if (categories.childs == "") {
            return (
              <SwiperSlide key={i}>
                <Link
                  to={{
                    pathname: `${props.Link}/categories/${categories.category_id}`,
                  }}
                >
                  <div className=" w-40">
                    <div className=" rounded-xl overflow-hidden">
                      <img
                        src={categories.category_image}
                        className="h-40 w-100 object-cover mx-auto border-brand-color border box-border block border-solid "
                      />
                      <span className="bg-slate-100 mx-auto w-full block  text-xs py-2 text-center">
                        {categories.category_name}
                      </span>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </div>
  );
}
export default IconsSwiperEn;
