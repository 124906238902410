import { Route, Routes } from "react-router-dom";
import "../../App.css";
import Categories from "../../pages/categories/categories";
import Homepage from "../../pages/homePage/homePage";
import Footer from "../../components/footer/footer";
import Nav2 from "./nav2";
import AllProducts from "../../pages/allProducts/allProducts";
import AllCategories from "../../pages/allCategories/allCategories";
import AllProjects from "../../pages/allProjects/allProjects";
import AboutPage from "../../pages/aboutUs/aboutUs";
import Nav3 from "./nav3";
import Search from "../../components/search/search";
import SinglePage from "../../pages/singlePage/singlePage";
import PageNotFound from "../pageNotFound/pageNotFound";
import Nav1 from "./nav1";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import TopNav from "./topNav";
import ScrollToTop from "../ScrollToTop";

function MainNav() {
  // const [showNav, setShowNav] = useState(true);
  const [inputProduct, setinputProduct] = useState("");
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(window.localStorage.getItem("language"));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("language", language);
    if (language == "Ar") {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  }, [language]);
  const hostLink = "https://nabatagri.com";
  // const hostLink = "http://localhost";
  const Link = "";
  return (
    <div style={{ direction: language == "Ar" && "rtl" }}>
      <ScrollToTop />

      <div className={` bg-opacity-50`}>
        <TopNav language={language} hostLink={hostLink} />
        <Nav2
          hostLink={hostLink}
          Link={Link}
          language={language}
          setinputProduct={setinputProduct}
          setLanguage={setLanguage}
        />
        <Nav1 Link={Link} hostLink={hostLink} language={language} />
        {/* <Nav3 Link={Link} hostLink={hostLink} language={language} /> */}
      </div>

      <Routes>
        <Route path={`${Link}/*`} element={<PageNotFound />} />
        <Route
          exact
          path={`${Link}`}
          element={
            <Homepage language={language} hostLink={hostLink} Link={Link} />
          }
        />
        <Route
          exact
          path={`${Link}/search/:input`}
          element={
            <Search
              Link={Link}
              language={language}
              inputProducts={inputProduct}
              hostLink={hostLink}
            />
          }
        />
        <Route
          exact
          path={`${Link}/all-products`}
          element={
            <AllProducts language={language} hostLink={hostLink} Link={Link} />
          }
        />
        <Route
          exact
          path={`${Link}/all-projects`}
          element={
            <AllProjects language={language} hostLink={hostLink} Link={Link} />
          }
        />
        <Route
          exact
          path={`${Link}/all-categories`}
          element={
            <AllCategories
              language={language}
              hostLink={hostLink}
              Link={Link}
            />
          }
        />
        <Route
          exact
          path={`${Link}/about-us`}
          element={
            <AboutPage language={language} hostLink={hostLink} Link={Link} />
          }
        />
        <Route
          exact
          path={`${Link}/categories/:id`}
          element={
            <Categories language={language} hostLink={hostLink} Link={Link} />
          }
        />
        <Route
          exact
          path={`${Link}/single-page/:productOrProject/:id`}
          element={
            <SinglePage language={language} hostLink={hostLink} Link={Link} />
          }
        />
      </Routes>
      <Footer language={language} hostLink={hostLink} Link={Link} />
    </div>
  );
}
export default MainNav;
