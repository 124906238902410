import React, { useEffect, useState } from "react";
import StyledTitle from "../styledTitle/styledTitle";
import { useTranslation } from "react-i18next";
function About(props) {
  const [About, fetchAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const GetAbout = () => {
    if (props.language == "Ar") {
      fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about-ar`)
        .then((res) => res.json())
        .then((res) => {
          fetchAbout(res);
          setIsLoading(false);
        });
    } else {
      fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about`)
        .then((res) => res.json())
        .then((res) => {
          fetchAbout(res);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    GetAbout();
  }, [props.language]);

  const { t } = useTranslation();
  return (
    <div className="min-h-screen">
      {isLoading && props.inhome != "homePage" ? (
        <div
          className="spinner-border text-primary  relative  ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="bg-white my-14">
          <div className="container_custom">
            <div className="row">
              <StyledTitle aboutUs={t("About Us")} />
              <span className="col-md-6">
                <img
                  className="about-us-image entered loaded w-100 object-cover rounded-md "
                  src={About.file_path}
                />
              </span>
              <span className="col-md-6">
                <div>
                  <h2 className="mb-8" style={{ color: "rgb(137, 165, 142)" }}>
                    {About.title}
                  </h2>
                  <p
                    className="mt-8 md:mt-0 text-justify"
                    dangerouslySetInnerHTML={{ __html: About.description }}
                  ></p>
                </div>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default About;
