// Import Swiper styles
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

function ProductsCards(props) {
  const [Products, fetchProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getProducts = () => {
    if (props.language == "Ar") {
      if (props.limit) {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/products-ar?limit=${props.limit}&offset=0`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else if (props.product_id) {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/related-products-ar?id=${props.product_id}&categoryId=${props.category_id}`
        )
          .then((res) => res.json())

          .then((res) => {
            setIsLoading(false);

            fetchProducts(res);
          });
      } else if (props.allProducts || props.inputProducts == "") {
        fetch(
          `${
            props.hostLink
          }/nabatagri_backend/web/API/api/products-ar?limit=${50}&offset=0`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else if (props.filter_categories) {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/categories-single-page-ar?id= ` +
            props.filter_categories
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else {
        fetch(
          `${
            props.hostLink
          }/nabatagri_backend/web/API/api/products-search-ar?limit=${50}&offset=0&search_new=${
            props.inputProducts
          }`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      }
    } else {
      if (props.limit) {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/products?limit=${props.limit}&offset=0`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else if (props.product_id) {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/related-products?id=${props.product_id}&categoryId=${props.category_id}`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else if (props.allProducts) {
        fetch(
          `${
            props.hostLink
          }/nabatagri_backend/web/API/api/products?limit=${50}&offset=0`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else if (props.filter_categories) {
        fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/categories-single-page?id=` +
            props.filter_categories
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      } else {
        fetch(
          `${
            props.hostLink
          }/nabatagri_backend/web/API/api/products-search?limit=${50}&offset=0&search_new=${
            props.inputProducts
          }`
        )
          .then((res) => res.json())
          .then((res) => {
            fetchProducts(res);
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    getProducts();
  }, [
    props.product_id,
    props.filter_categories,
    props.inputProducts,
    props.language,
  ]);

  //load more start
  const getRemainingProducts = async () => {
    if (props.language == "Ar") {
      if (!props.allProducts) {
        const res = await fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/products-search-ar?offset=${offset}&limit=50&search_new=${props.inputProducts}`
        );
        const data = res.json();
        return data;
      } else {
        const res = await fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/products-ar?offset=${offset}&limit=50`
        );
        const data = res.json();
        return data;
      }
    } else {
      if (!props.allProducts) {
        const res = await fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/products-search?offset=${offset}&limit=50&search_new=${props.inputProducts}`
        );
        const data = res.json();
        return data;
      } else {
        const res = await fetch(
          `${props.hostLink}/nabatagri_backend/web/API/api/products?offset=${offset}&limit=50`
        );
        const data = res.json();
        return data;
      }
    }
  };
  const fetchData = async () => {
    const infiniteScroll = await getRemainingProducts();
    fetchProducts([...Products, ...infiniteScroll]);
    if (infiniteScroll.length === 0 || infiniteScroll.length < 50) {
      alert("s");
      setHasMore(false);
    }
    setOffset(offset + 50);
  };
  //load more end
  return (
    <div
      className={`${props.limit || props.product_id ? null : "min-h-screen"}`}
    >
      {isLoading ? (
        <div
          className="spinner-border text-primary  relative  ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          {Products.length > 0 ? (
            props.limit ||
            props.filter_categories ||
            props.product_id ||
            Products.length <= 50 ? (
              <div
                className={`grid grid-cols-2 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-2 col-span-12 ${
                  props.language == "Ar" ? "lg:float-right" : "lg:float-left"
                }`}
              >
                {Products.map((product, i) => {
                  return (
                    <div key={i}>
                      <Link
                        to={{
                          pathname: `${props.Link}/single-page/product/${product.product_id}`,
                        }}
                      >
                        <div
                          className="rounded-lg m-2 sm:m-3 text-white group overflow-hidden shadow-sm hover:shadow-xl pb-0 "
                          style={{ backgroundColor: "rgba(137, 165, 142, 1)" }}
                        >
                          <div className=" relative cursor-pointer rounded-lg">
                            <div className="w-full">
                              <div className="w-full relative overflow-hidden ">
                                <div className=" absolute p-1 z-10 bg-white rounded-md text-gray-600 m-2 shadow-sm font-bold">
                                  {product.category_name}
                                </div>
                                <img
                                  className=" mb-2 w-full h-48 bg-center group-hover:scale-105 transition-all object-cover"
                                  src={product.main_image}
                                  width="210"
                                  height="210"
                                  alt="YouPhone Phone 13 Pro Max 1 TB Grafit"
                                />
                              </div>
                              <div className="w-full text-center ">
                                <span
                                  key={i}
                                  className=" w-full text-center h-11 overflow-hidden px-1  font-bold"
                                >
                                  {product.product_name}
                                </span>
                                <div className=" text-center text-md z-10 relative ">
                                  <span
                                    className={`mt-2 mb-2  ${
                                      props.language == "Ar"
                                        ? "text-right"
                                        : "text-left"
                                    }  block text-xs pl-4 pr-4`}
                                  >
                                    {product.product_brief_description && (
                                      <span>
                                        {product.product_brief_description}
                                        {product.product_brief_description
                                          .length < 55
                                          ? null
                                          : "..."}
                                      </span>
                                    )}
                                  </span>
                                  <span className=" line-through text-xs w-full float-left "></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <InfiniteScroll
                  dataLength={Products.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={hasMore}
                  scrollThreshold={0.5}
                  loader={
                    <div
                      className="spinner-border text-primary  relative ml-auto mr-auto !block mt-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }
                  className="overflow-hidden"
                >
                  <div className="flow-root mb-5">
                    {Products.map((product, i) => {
                      // props.setRelated && props.setRelated(Products);
                      return (
                        <div
                          key={i}
                          className={`h-80  overflow-hidden   ${
                            props.language == "Ar"
                              ? "float-right"
                              : "float-left"
                          } rounded-lg group  w-6/12 md:w-1/5`}
                        >
                          <Link
                            to={{
                              pathname: `${props.Link}/single-page/product/${product.product_id}`,
                            }}
                          >
                            {/* <Link to={{ pathname: '/productSinglePage', search: `?product_id=${product.product_id}` }}> */}
                            <div
                              className="rounded-lg m-2 sm:m-3 text-white group overflow-hidden shadow-sm hover:shadow-xl pb-0 "
                              style={{
                                backgroundColor: "rgba(137, 165, 142, 1)",
                              }}
                            >
                              <div className=" relative cursor-pointer rounded-lg">
                                <div className="w-full">
                                  <div className="w-full relative overflow-hidden ">
                                    <div className=" absolute p-1 z-10 bg-white rounded-md text-gray-600 m-2 shadow-sm font-bold">
                                      {product.category_name}
                                    </div>
                                    <img
                                      className=" mb-2 w-full h-48 bg-center group-hover:scale-105 transition-all object-cover"
                                      src={product.main_image}
                                      width="210"
                                      height="210"
                                    />
                                  </div>
                                  <div className="w-full text-center ">
                                    <span
                                      key={i}
                                      className=" w-full text-center h-11 overflow-hidden px-1  font-bold"
                                    >
                                      {product.product_name}
                                    </span>
                                    <div className=" text-center text-md z-10 relative ">
                                      <span
                                        className={`mt-2 mb-2 ${
                                          props.language == "Ar"
                                            ? "text-right"
                                            : "text-left"
                                        } block text-xs pl-4 pr-4`}
                                      >
                                        <span>
                                          {product.product_brief_description}
                                          {product.product_brief_description
                                            .length < 55
                                            ? null
                                            : "..."}
                                        </span>
                                      </span>
                                      <span className=" line-through text-xs w-full float-left "></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            )
          ) : (
            <h6>{t("No Products Found !")}</h6>
          )}
        </>
      )}
    </div>
  );
}
export default ProductsCards;
