import About from "../../components/about/about";

function AboutPage(props) {
  if (props.inhome) {
    var inhome = props.inhome;
  } else {
    var inhome = "";
  }
  return (
    <About
      hostLink={props.hostLink}
      Link={props.Link}
      language={props.language}
      inhome={inhome}
    />
  );
}
export default AboutPage;
