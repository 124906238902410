import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../App.css";
import CarouselSwiper from "../../components/carouselSwiper/carouselSwiper";
import IconsSwiper from "../../components/iconsSwiper/iconsSwiper";
import PinterestImagesCategory from "../../components/pinterestImagesCategory/pinterestImagesCategory";
import ProductsCards from "../../components/productsCards/productsCards";
import ProjectsSwiperCards from "../../components/projectsSwiperCards/projectsSwiperCards";
import AboutPage from "../aboutUs/aboutUs";
import GoogleMaps from "../../components/googleMaps/googleMaps";

function Homepage(props) {
  const { t } = useTranslation();
  return (
    <div>
      <CarouselSwiper
        hostLink={props.hostLink}
        Link={props.Link}
        language={props.language}
      />
      <IconsSwiper
        hostLink={props.hostLink}
        Link={props.Link}
        language={props.language}
        limit={10}
        offset={0}
      />
      <div className="  w-full ">
        {" "}
        <div className={`container_custom  grid grid-cols-1`}>
          <span className=" shadow-2xl bg-white   bg-opacity-50 lg:p-4  my-0 lg:my-8 rounded-3xl">
            <span>
              <span className="grid">
                <div className=" col-span-12 text-center  ">
                  <h1>{t("New Products")}</h1>
                  <h2 className="text-lg ">
                    {t("We Added New Products For You")}
                  </h2>
                </div>
                <Link
                  to={{ pathname: `${props.Link}/all-products` }}
                  className="sm:block hidden absolute p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm "
                >
                  {t("View All")}
                </Link>
              </span>
              <div className="sm:hidden block text-center">
                <Link
                  to={{ pathname: `${props.Link}/all-products` }}
                  className=" relative p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm "
                >
                  {t("View All")}
                </Link>
              </div>
            </span>
            <ProductsCards
              hostLink={props.hostLink}
              Link={props.Link}
              language={props.language}
              limit={10}
            />
          </span>
        </div>
      </div>
      <AboutPage
        hostLink={props.hostLink}
        Link={props.Link}
        language={props.language}
        inhome="homePage"
      />
      <PinterestImagesCategory
        hostLink={props.hostLink}
        Link={props.Link}
        language={props.language}
      />
      <GoogleMaps
        hostLink={props.hostLink}
        Link={props.Link}
        language={props.language}
      />
      <ProjectsSwiperCards
        hostLink={props.hostLink}
        Link={props.Link}
        language={props.language}
        limit={10}
      />
      {/* <OffersCards /> */}
    </div>
  );
}
export default Homepage;
