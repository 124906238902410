import classes from "./styledTitle.module.css";

function StyledTitle(props) {
  return (
    <div className={`${classes.one} mb-10 py-10 `}>
      <h1 className={classes.titleH1}>{props.aboutUs}</h1>
    </div>
  );
}
export default StyledTitle;
