import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StyledTitle from "../styledTitle/styledTitle";
function GoogleMaps(props) {
  const [About, fetchAbout] = useState([]);
  const GetAbout = () => {
    if (props.language == "Ar") {
      fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about-ar`)
        .then((res) => res.json())
        .then((res) => {
          fetchAbout(res);
        });
    } else {
      fetch(`${props.hostLink}/nabatagri_backend/web/API/api/about`)
        .then((res) => res.json())
        .then((res) => {
          fetchAbout(res);
        });
    }
  };
  useEffect(() => {
    GetAbout();
  }, [props.language]);

  const { t } = useTranslation();
  return (
    <div className="bg-white">
      <div className="container_custom my-14">
        <div className="row">
          <StyledTitle aboutUs={t("Our Branches")} />
          <div
            className="col-lg-6  col-md-12 col-sm-12 relative"
            style={{ textAlign: "-webkit-center" }}
          >
            <iframe
              className="mb-10 shadow-2xl"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d833.8967871715533!2d35.21605382918589!3d33.27729911999848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xedf72e5e651fca62!2zMzPCsDE2JzM4LjMiTiAzNcKwMTInNTkuOCJF!5e0!3m2!1sen!2slb!4v1659340784614!5m2!1sen!2slb"
              width="450"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <h2 className="text-center" style={{ color: "rgb(137, 165, 142)" }}>
              Nabatagri Office
            </h2>
            <p className="font-medium mb-5">{About.address}</p>
          </div>
          <div
            className="col-lg-6 col-md-12  col-sm-12 relative "
            style={{ textAlign: "-webkit-center" }}
          >
            <iframe
              className="mb-10 shadow-2xl"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d429.07627299426167!2d35.232063964202275!3d33.29552686210358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151e87eb420d439b%3A0x848871fa67c379b2!2sNabat%20Nursery!5e0!3m2!1sen!2slb!4v1659345734301!5m2!1sen!2slb"
              width="450"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <h2 className="text-center" style={{ color: "rgb(137, 165, 142)" }}>
              Nabatagri Nursery
            </h2>
            <p className="font-medium mb-5">{About.nursery_address}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GoogleMaps;
