import { useTranslation } from "react-i18next";
import CategoriesCards from "../../components/categoriesCards/categoriesCards";

function AllCategories(props) {
  const { t } = useTranslation();

  return (
    <div className={` `}>
      <div className={`container_custom mb-14`}>
        <div className=" table ">
          <div className=" col-span-12 text-center mb-2">
            <h1>{t("All Categories")}</h1>
          </div>
        </div>
        <div className="row homeFirsBoxs">
          <CategoriesCards
            hostLink={props.hostLink}
            Link={props.Link}
            language={props.language}
            limit={6}
          />
        </div>
      </div>
    </div>
  );
}
export default AllCategories;
