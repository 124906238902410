import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductsCards from "../productsCards/productsCards";

function RelatedProducts(props) {
  const { t } = useTranslation();
  const [Products, fetchProducts] = useState([]);

  const getProducts = () => {
    if (props.language == "Ar") {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/related-products-ar?id=${props.product_id}&categoryId=${props.category_id}`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchProducts(res);
        });
    } else {
      fetch(
        `${props.hostLink}/nabatagri_backend/web/API/api/related-products?id=${props.product_id}&categoryId=${props.category_id}`
      )
        .then((res) => res.json())
        .then((res) => {
          fetchProducts(res);
        });
    }
  };

  useEffect(() => {
    getProducts();
  }, [
    props.product_id,
    props.filter_categories,
    props.inputProducts,
    props.language,
  ]);
  return (
    <>
      {Products != "" ? (
        <div className="p-2 shadow-2xl bg-white   bg-opacity-50 lg:p-4 grid grid-cols-1 my-0 lg:my-8 rounded-3xl mt-5 related_products">
          <h2 className="w-max">{t("Related Products")}</h2>

          <ProductsCards
            hostLink={props.hostLink}
            Link={props.Link}
            language={props.language}
            product_id={props.product_id}
            category_id={props.category_id}
            Products={Products}
          />
        </div>
      ) : null}
    </>
  );
}
export default RelatedProducts;
